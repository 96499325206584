export enum ExperimentKey {
  HomepageMessaging = "062824-home-page-messaging",
  LimitAnonymousAccess = "limit-anonymous-access-10-20",
  HomepageCtas = "2024-09-16-homepage-ctas",
  GrowthbookVisualExperiments = "growthbook-visual-experiments",
}

// You can find all feature flags here: https://us.posthog.com/project/71364/feature_flags
export type Experiments = Partial<{
  [ExperimentKey.HomepageMessaging]: {
    variant: "control" | "challenger";
    payload: undefined;
  };

  [ExperimentKey.LimitAnonymousAccess]: {
    variant: "control" | "max-10" | "max-20";
    payload: undefined;
  };

  [ExperimentKey.HomepageCtas]: {
    variant:
      | "control"
      | "no-madlibs-remove-ctas"
      | "madlibs-keep-ctas"
      | "madlibs-remove-ctas"
      | "question-library-keep-ctas"
      | "question-library-remove-ctas";
    payload: undefined;
  };

  [ExperimentKey.GrowthbookVisualExperiments]: {
    variant: boolean;
    payload: undefined;
  };
}>;

// Mapped type from the source of truth Experiments, don't change it unless posthog has changed their api response
// Posthog returns this schema instead, we do some runtime work to convert from this to the Experiments schema
export type PosthogExperimentsResponse = {
  featureFlags: {
    [ExperimentKey in keyof Experiments]: NonNullable<Experiments[ExperimentKey]>["variant"];
  };
  featureFlagPayloads: {
    [ExperimentKey in keyof Experiments]: NonNullable<Experiments[ExperimentKey]>["payload"];
  };
};
