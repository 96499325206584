// @refresh reload
import { StartClient, mount } from "@solidjs/start/client";

const getRoot = () => {
  const root = document.getElementById("app");
  if (!root) {
    // TODO: Observe Error
    throw new Error("Root element not found");
  }
  return root;
};

mount(() => <StartClient />, getRoot());

export default null;
